app-security-control-domains {
    app-badge[type='light-gray'] {
        > .badge {
            color: #5b6270 !important;
            font-size: 0.8rem;
            padding: 4px 5px;
        }

        &.exceptions-badge {
            > .badge {
                color: #e61f3f !important;
            }
        }
        &.subservicer-badge {
            > .badge {
                color: #4457e6 !important;
            }
        }
        &.shared-responsibility-badge {
            > .badge {
                color: #0098af !important;
            }
        }
        &.cuec-badge {
            > .badge {
                color: #4457e6 !important;
                text-transform: uppercase;
            }
        }
    }

    app-control-group {
        mat-card {
            overflow-x: hidden;
            &.disabled,
            &.out-of-scope {
                background-color: #fafbfb;

                .mat-expansion-panel {
                    background-color: #fafbfb;
                }
            }
        }
        mat-accordion {
            .mat-expansion-panel {
                @extend .border-top;
            }
            .mat-expansion-panel-header {
                @extend .ps-2;
            }
            .mat-expansion-panel-body {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
}
