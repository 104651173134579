/* ========================================================================
     Component: bootstrap-custom
 ========================================================================== */

// Use this file to add custom css that may
// depend on Bootstrap styles

// Different container size to wrap content
.container-sm {
    max-width: map-get($container-max-widths, 'sm');
    width: auto;
}

.container-md {
    max-width: map-get($container-max-widths, 'md');
    width: auto;
}

.container-lg {
    max-width: map-get($container-max-widths, 'lg');
    width: auto;
}

.container-xl {
    max-width: map-get($container-max-widths, 'xl');
    width: auto;
}

// Remove padding and collapse columns
.row-flush {
    margin: 0;
    > .col,
    > [class*='col-'] {
        padding-left: 0;
        padding-right: 0;
    }
}

// Breadcrumb variants

.breadcrumb {
    font-weight: normal;
    border-radius: 0;
    color: $text-muted;
    padding: 16px 20px;
}

.breadcrumb-item {
    // The separator between breadcrumbs (by default, a forward-slash: "/", replaced with ›)
    + .breadcrumb-item::before {
        background-color: $breadcrumb-bg;
        float: none;
        font-family: $breadcrumb-divider-font-family;
        content: $breadcrumb-divider;
        line-height: 1rem;
        vertical-align: middle;
        font-weight: 500;
    }

    &.active {
        font-weight: 600;
        color: $accent;
    }
}

.content-heading {
    // Breadcrumb below title
    .breadcrumb {
        font-size: 0.8125rem;
        margin-bottom: 0;
    }
    // Breadcrumb next to view title
    + .breadcrumb {
        margin: -25px -25px 20px -20px;
        background-color: $content-heading-bg;
        border-top: 1px solid $content-heading-border;
        border-bottom: 1px solid $content-heading-border;
    }
}

// Different size of Progress bars
$progress-height-sm: 15px;
$progress-height-xs: 8px;

.progress-sm {
    height: $progress-height-sm;
}

.progress-xs {
    height: $progress-height-xs;
}

.badge-light {
    color: #212529 !important;
    background-color: #dee2e6 !important;
}

// Form Rounded
.form-control-rounded {
    border-radius: 100px;
}

// Auto color validation of input group
// Support only input-group-append
.input-group.with-focus {
    .form-control {
        transition: none;
        &:focus + .input-group-append > .input-group-text {
            border-color: $input-focus-border-color;
        }
        &.ng-touched.ng-invalid,
        &.ng-touched.ng-invalid + .input-group-append > .input-group-text {
            border-color: $danger;
        }
        &.ng-touched.ng-valid,
        &.ng-touched.ng-valid + .input-group-append > .input-group-text {
            border-color: $success;
        }
    }
}

.text-muted {
    --bs-secondary-color: #3f484a;
}

.row {
    --bs-gutter-x: 2rem;
}

.card-border {
    background: #ffffff;
    border: 1px solid;
    border-radius: 2px;
    border-color: #d2dbe1 !important;
}

// Make the default a little smaller and more visible
.btn-close {
    --bs-btn-close-focus-shadow: 0 0 0 0.05rem rgba(26, 32, 65, 0.25);
    --bs-btn-close-hover-opacity: 0.8;
    width: 0.75em;
    height: 0.75em;
    background: transparent var(--bs-btn-close-bg) center/.75em auto no-repeat;
}

.modal-footer {
    padding: 1rem;

    & > :not(:last-child) {
        margin: 0 0.25rem 0 0;
    }
    & > :not(:first-child) {
        margin: 0 0 0 0.25rem;
    }
}

.table {
    --bs-table-accent-bg: transparent;
    --bs-table-hover-bg: #fafbff;
}
.table-group-divider {
    border-top-color: $table-border-color;
}
.table th {
    padding: 0.75rem;
}
