[appNotificationMenu],
.notifications-previewer {
    app-event-notification,
    app-tip-notification,
    app-link-notification {
        width: 100%;
        height: 100%;
    }

    .notification-body {
        width: 100%;
        padding: 0.5rem 0.7rem;
        padding-left: 0;
        display: grid;
        grid-template-columns: 0.7rem minmax(100px, 1fr) 30px;
        grid-template-areas:
            'new title action'
            'new title action'
            '. time action'
            '. subtitle action';
        cursor: pointer;

        &:hover {
            .notification-action {
                opacity: 0.5;
            }
        }

        .notification-new {
            grid-area: new;
            height: 0.4rem;
            width: 0.4rem;
            border-radius: 50%;
            background-color: #fed400;
            margin-top: 0.2rem;
            margin-left: 0.2rem;
        }

        .notification-title {
            grid-area: title;
            word-wrap: normal;
            white-space: normal;
            font-size: 0.79rem;

            .vendor {
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .notification-time {
            grid-area: time;
        }

        .notification-subtitle {
            grid-area: subtitle;
        }

        .notification-time,
        .notification-subtitle {
            padding-top: 0.2rem;
            font-size: 0.702rem;
        }

        .notification-action {
            display: flex;
            grid-area: action;
            align-items: center;
            justify-content: center;
            align-self: center;
            cursor: pointer;
            opacity: 0;
            transition:
                background-color,
                opacity 300ms ease;
            height: 2rem;

            &:hover {
                background-color: white;
                border-radius: 5px;
                opacity: 1;
            }
        }
    }

    app-tip-notification {
        .notification-body {
            background-color: #f3fbfa;
        }
    }
}
