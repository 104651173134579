@import '../palette';

// Cutoms Gray colors for theme
$gray-darker: #232735;
$gray-dark: #3a3f51;
$gray: #dde6e9;
$gray-light: #e4eaec;
$gray-lighter: #edf1f2;

// Custom brand colors for theme
$inverse: #131e26;
$purple: #7266ba;
$pink: #f532e5;
$yellow: #fad732;
$green: #239f43;
$gold: #fff765;
$blue: #1a2041;
$cyan: #01b3e6;
$warning-accent: #e61f3f;
$red: #c62828;

$primary: map-get($viso-primary, 500);
$secondary: #fff;
$secondary-mat: map-get($viso-secondary, 500);
$accent: map-get($viso-accent, 500);
$success: map-get($viso-success, 500);
$error: map-get($viso-error, 500);
$info: map-get($viso-info, 500);
$info-bg-subtle: #eaf5ff;
$warning: map-get($viso-warning, 500);
$warning-bg-subtle: #fffcc5;
$danger: map-get($viso-error, 500);
$danger-bg-subtle: #f9d9d9;
$dark: #3a3f51;
$secondary-text: #3964ff;
$secondary-bg: #e4eaff;
$secondary-text-light: #8aa0f0;
$disabled-select: #517386;
$secondary-container-text: #004f58;
$viso-surface-container: #f5fafb;

// Defining root variables
:root {
    --bootstrap-primary: #{$primary};
    --bootstrap-success: #{$success};
    --bootstrap-info: #{$info};
    --bootstrap-warning: #{$warning};
    --bootstrap-danger: #{$danger};
    --bs-list-group-border-width: 1px;
    --bs-list-group-border-color: $gray-light;
}

// Custom color variants
$amountOfLight: 10%;

$primary-light: lighten($primary, $amountOfLight);
$success-light: lighten($success, $amountOfLight);
$info-light: lighten($info, $amountOfLight);
$warning-light: lighten($warning, $amountOfLight);
$danger-light: lighten($danger, $amountOfLight);
$inverse-light: lighten($inverse, $amountOfLight);
$green-light: lighten($green, $amountOfLight);
$pink-light: lighten($pink, $amountOfLight);
$purple-light: lighten($purple, $amountOfLight);
$yellow-light: lighten($yellow, $amountOfLight);
$primary-dark: darken($primary, $amountOfLight);
$success-dark: darken($success, $amountOfLight);
$info-dark: darken($info, $amountOfLight);
$warning-dark: darken($warning, $amountOfLight);
$danger-dark: darken($danger, $amountOfLight);
$error-dark: darken($error, $amountOfLight);
$inverse-dark: darken($inverse, $amountOfLight);
$green-dark: darken($green, $amountOfLight);
$pink-dark: darken($pink, $amountOfLight);
$purple-dark: darken($purple, $amountOfLight);
$yellow-dark: darken($yellow, $amountOfLight);

// Theme variables

// used for sidebars and content spacing
$navbar-height: 60px;

// Button secondary redefinition style for theme
$btn-secondary-color: #333;
$btn-secondary-bg: #fff;
$btn-secondary-border: #eaeaea;

// Top Border color for custom default cards (.card-default)
$card-default-border-color: #cfdbe2;

// ---------------------
// Utilities variables
// ---------------------

$text-alpha: rgba(255, 255, 255, 0.5);
$text-alpha-inverse: rgba(0, 0, 0, 0.5);
// On surface variant
$text-light: #3f484a;

$font-size-base: 1rem;
$text-xs: $font-size-base * 0.63785;
$text-sm: $font-size-base * 0.74375;
$text-md: $font-size-base * 1.3625;
$text-lg: $font-size-base * 2.625;

// Borders
$border-color: rgba(0, 0, 0, 0.12);
$border-color-darker: rgba(0, 0, 0, 0.24);

$wd-xxs: 60px;
$wd-xs: 90px;
$wd-sm: 150px;
$wd-sd: 200px; // sd == standard
$wd-md: 240px;
$wd-lg: 280px;
$wd-xl: 320px;
$wd-xxl: 360px;
$wd-wide: 100%;
$wd-auto: auto;
$wd-zero: 0;

// ---------------------
// Bootstrap 4 Variables Override
// ---------------------

// Color system

$colors: (
    'purple': $purple,
    'pink': $pink,
    'yellow': $yellow,
    'green': $green,
    'primary': $primary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'inverse': $inverse,
    'primary-light': $primary-light,
    'primary-dark': $primary-dark,
    'success-light': $success-light,
    'success-dark': $success-dark,
    'info-light': $info-light,
    'info-dark': $info-dark,
    'warning-light': $warning-light,
    'warning-dark': $warning-dark,
    'danger-light': $danger-light,
    'danger-dark': $danger-dark,
    'error-dark': $error-dark,
    'green-light': $green-light,
    'green-dark': $green-dark,
    'pink-light': $pink-light,
    'pink-dark': $pink-dark,
    'purple-light': $purple-light,
    'purple-dark': $purple-dark,
    'inverse-light': $inverse-light,
    'inverse-dark': $inverse-dark,
    'yellow-light': $yellow-light,
    'yellow-dark': $yellow-dark,
);

// Heading
$heading-color: #1c1b1f;

// Body
$body-bg: white;
$body-color: #292f36;

$border-radius: 0.25rem;
$border-radius-lg: 0.375rem;

$line-height-base: 1.528571429;

$headings-line-height: 1.1;

// On surface variant
$text-muted: #3f484a;

// Tables

$bs-table-accent-bg: #fafbfc;
$bs-table-hover-bg: #fafbff;

$bs-table-border-color: #eee;

// Buttons + Forms

$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 1rem;

$input-btn-padding-y-sm: 0.3125rem;
$input-btn-padding-x-sm: 0.625rem;

$input-btn-padding-y-lg: 0.625rem;
$input-btn-padding-x-lg: 1.25rem;

// Forms

$input-disabled-bg: $gray-lighter;
$input-border-color: $gray;
$input-focus-border-color: #66afe9;
$input-placeholder-color: #b7bac9;

// Dropdowns

$dropdown-border-color: #e1e1e1;

// Navs

$nav-tabs-link-active-bg: #fff;

// List group

$list-group-border-color: #e4eaec;

// Image thumbnails

$thumbnail-padding: 0.125rem;

// Breadcrumbs

$breadcrumb-bg: #fafafa;
$breadcrumb-divider-color: #3c4045;
$breadcrumb-active-color: $primary;
$breadcrumb-divider: '\e5cc';
$breadcrumb-divider-font-family: 'material icons';

// Icons
$icon-color: #bebebe;

// Panel
$panel-box-shadow: 0 0 15px 0px darkgrey;

// Chips
$chip-success: #43a047;
$chip-danger: #ba1a1a;
$chip-basic: #9ca8ad;
$chip-basic-text: #46464e;
$chip-outline-bg: #fff;
$chip-info: #0288d1;

// Fabs
$fab-outline: #9ca8ad;
$fab-fg-outlined: #515b92;
$fab-bg-primary: #282e50;
$fab-bg-secondary: #4757c8;

// Sensitivity Meter
$sensitivity-meter-bg: #515b92;
$sensitivity-meter-value-bg: #b6bbe5;
$sensitivity-meter-label: #bfc4ee;
$sensitivity-meter-label-invert: #dee0ff;
$sensitivity-meter-font-weight: 500;

// Mat List
$list-title: #909099;
$list-title-custom: #1e2fa4;
$list-name: #1b1b1e;
$list-description: #46464e;

$article-border-color: #c7c5cf;
$article-icon-color: #006875;

// List nav button header divider border color
$nav-button-header-divider-border-color: #c7c5cf;

// List header toggle button group
$button-toggle-group-color: #77767f;
$button-toggle-group-checked-text-color: #515b92;
$button-toggle-group-checked-background-color: #dee0ff;
$button-toggle-group-background-color: #f3f0f5;
$button-toggle-group-background-color-checked: #dee0ff;

// vendor logo icon
$vendor-logo-background: #c4c4c4;
