@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');
    $neutral-palette: map.get($color-config, 'neutral');

    // Additional palettes
    $secondary-palette: map.get($color-config, 'secondary');
    $success-palette: map.get($color-config, 'success');

    .viso-button {
        &.mat-mdc-button-base {
            text-decoration: none;
        }
        &.mat-mdc-outlined-button {
            --mdc-outlined-button-outline-color: currentColor;
            &:hover {
                border-color: transparent;
            }
        }
        &.link {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
                .mdc-button__ripple::before {
                    opacity: 0 !important;
                }
            }
        }
        &.cancel {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
                .mdc-button__ripple::before {
                    opacity: 0 !important;
                }
            }
        }
    }

    .mdc-icon-button {
        $small-icon-button-size: 2rem;

        &.mat-secondary:not(:disabled) {
            color: mat.get-color-from-palette($secondary-palette, default);
        }

        &.small-icon-button {
            height: $small-icon-button-size;
            width: $small-icon-button-size;
            padding: $small-icon-button-size / 4;

            .mat-icon {
                height: $small-icon-button-size / 2;
                width: $small-icon-button-size / 2;
                font-size: $small-icon-button-size / 2;
            }

            .mat-mdc-button-touch-target {
                height: $small-icon-button-size;
                width: $small-icon-button-size;
            }
        }
    }

    .mdc-button {
        letter-spacing: normal !important;

        &.mat-success:not(:disabled) {
            color: mat.get-color-from-palette($success-palette, default);
        }

        &.mat-secondary:not(:disabled) {
            color: mat.get-color-from-palette($secondary-palette, default);
            border-color: mat.get-color-from-palette($secondary-palette, default);
        }

        &.mat-primary:not(:disabled) {
            border-color: mat.get-color-from-palette($primary-palette, default);
        }

        &.mat-neutral:not(:disabled) {
            border-color: mat.get-color-from-palette($neutral-palette, default);
        }

        &.text-button {
            padding: 0;
            min-width: 0;
            text-decoration: none;
            :not(.mdc-button__label) {
                display: none;
            }
        }

        &.no-left-radius {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &.no-left-radius {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &.icon {
            height: 32px;
            width: 32px;
            min-width: 32px;
        }

        &.centered {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.full-width {
            width: 100%;
        }

        &.hugh-content {
            min-width: initial;
        }

        &.full-height {
            height: 100%;
        }

        &.btn-labeled > .mdc-button__label {
            height: 100%;
            display: flex;
            align-items: center;
        }

        &.inline {
            width: auto;
            height: auto;
            min-width: initial;
        }

        &.truncate-label > .mdc-button__label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .mdc-icon-button {
        &.shrink {
            height: 24px;
            width: 24px;
            padding: 0;

            .mat-mdc-button-touch-target {
                height: 24px;
                width: 24px;
            }
        }
        &.small {
            --mdc-icon-button-state-layer-size: 24px;
            width: var(--mdc-icon-button-state-layer-size);
            height: var(--mdc-icon-button-state-layer-size);
            padding: 0;

            mat-icon {
                width: calc(var(--mdc-icon-button-state-layer-size) / 2);
                height: calc(var(--mdc-icon-button-state-layer-size) / 2);
                font-size: calc(var(--mdc-icon-button-state-layer-size) / 2);
                line-height: 1.3;
            }
        }
        &.centered {
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: center;
        }
    }

    .mdc-button--unelevated {
        &.mat-success:not(:disabled) {
            background-color: mat.get-color-from-palette($success-palette, default);
            color: mat.get-color-from-palette($success-palette, darker-contrast);
        }

        &.mat-secondary:not(:disabled) {
            background-color: mat.get-color-from-palette($secondary-palette, default);
            color: mat.get-color-from-palette($secondary-palette, default-contrast);
        }
    }
}

@mixin typography($theme) {
    // Get the typography config from the theme.
    $typography-config: mat.get-typography-config($theme);

    .viso-button,
    .mat-mdc-button-base,
    a.mdc-icon-button,
    button.mdc-icon-button {
        font-size: 13px;
    }
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }

    $typography-config: mat.get-typography-config($theme);
    @if $typography-config != null {
        @include typography($theme);
    }
}

.date-form-group {
    position: relative;
    .mat-datepicker-toggle {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.mat-datepicker-toggle {
    .mat-mdc-icon-button.mat-mdc-button-base {
        width: auto;
        height: 90%;
        padding: 4px;
    }
}

.mat-mdc-menu-content {
    .mat-mdc-menu-item-text {
        font-size: 0.875rem !important;
    }
}
