@use 'sass:map';
@use '@angular/material' as mat;
@import '../app/variables';

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');

    $gradient-start-color: #fafbfb;
    $gradient-end-color: #c5d6de;
    $table-hover-color: #fafbff;
    $table-cell-color: #e0e0e0;

    .mat-mdc-header-row {
        background: linear-gradient(0deg, $gradient-start-color, $gradient-start-color),
            linear-gradient(0deg, $gradient-end-color, $gradient-end-color) !important;
    }

    .mat-mdc-row,
    .mat-mdc-header-row {
        height: 42px !important;

        &:hover {
            background-color: $table-hover-color !important;
            transition: all 0.3s ease-in-out;
        }
    }

    .mat-mdc-header-cell {
        border-top: 1px solid $table-cell-color !important;
    }

    .mat-mdc-header-cell,
    .mat-mdc-cell {
        border-right: 1px solid $table-cell-color !important;

        &:first-of-type {
            border-left: 1px solid $table-cell-color !important;
        }
    }

    .mat-mdc-row:last-of-type {
        .mat-mdc-cell {
            border-bottom: 1px solid $table-cell-color !important;
        }
    }

    .mat-mdc-table {
        border-collapse: separate !important;
    }

    .viso-table {
        .mat-mdc-cell {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .mat-mdc-header-row {
            background: none !important;
        }

        .mat-mdc-header-cell,
        .mat-mdc-cell {
            border: none !important;
            border-bottom: 1px solid $table-cell-color !important;
        }

        .mat-mdc-header-cell {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.5px;
            color: #77767f;
        }

        .mat-sort-header-container {
            justify-content: space-between;
        }
    }
}
